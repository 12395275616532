<template>
  <base-section id="404" space="0">
    <v-container class="fill-height px-4 pt-12 pb-6">
      <v-row justify="center">
        <v-col cols="10">
          <base-subheading class="primary--text" title="Bienvenido!" />

          <base-subheading
            space="8"
            class="text-h6 primary--text"
            title="Para iniciar debes escanear un código QR."
          />

          <v-row justify="center">
            <v-icon size="140" class="mx-auto pt-6 pb-12" color="primary">
              mdi-qrcode-scan
            </v-icon>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "WelcomeSection",

  provide: {
    heading: { align: "center" },
  },
};
</script>
